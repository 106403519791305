var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Right Aligned Media With Round Images" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeRightAlign) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("Change the order of content in media objects by adding ")
        ]),
        _c("code", [_vm._v("right-align")]),
        _c("span", [_vm._v(" property.")]),
        _c("span", [_vm._v("For image circle ")]),
        _c("code", [_vm._v('rounded="circle"')]),
        _c("span", [_vm._v(" prop with ")]),
        _c("code", [_vm._v("<b-img>.")]),
        _c("span", [_vm._v("Use class ")]),
        _c("code", [_vm._v("text-right")]),
        _c("span", [_vm._v(" for text right.")])
      ]),
      _c(
        "div",
        { staticClass: "media-list" },
        [
          _c(
            "b-media",
            {
              staticClass: "text-right",
              attrs: { "right-align": "", "vertical-align": "top" },
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-10.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder",
                          rounded: "circle"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Oat cake ")
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " Oat cake topping oat cake jelly soufflé donut jelly-o tootsie roll. Candy sweet cake. Tiramisu cookie toffee donut. Chocolate pie croissant gummi bears muffin dessert chocolate. Generic placeholder image "
                )
              ])
            ],
            1
          ),
          _c(
            "b-media",
            {
              staticClass: "text-right",
              attrs: { "right-align": "", "vertical-align": "top" },
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder",
                          rounded: "circle"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Jelly chocolate cake ")
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " Jelly chocolate cake lemon drops halvah dragée caramels jelly-o biscuit. Fruitcake jelly beans marzipan sesame snaps.Jelly beans cake chocolate cake gummi bears lollipop. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-media",
            {
              staticClass: "text-right",
              attrs: { "right-align": "", "vertical-align": "top" },
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-2.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder",
                          rounded: "circle"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Pudding marshmallow ")
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " Pudding marshmallow cheesecake. Chocolate cake apple pie jelly-o bear claw ice cream sugar plum biscuit. Lemon drops brownie biscuit jelly-o biscuit gummies. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }