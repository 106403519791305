var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Left Aligned Media Objects" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLeftAlign) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(" The "),
        _c("code", [_vm._v("media object")]),
        _vm._v(
          " helps build complex and repetitive components where some media is positioned alongside content that doesn't wrap around said media. Plus, it does this with only two required classes thanks to flexbox. "
        )
      ]),
      _c(
        "div",
        { staticClass: "media-list" },
        [
          _c(
            "b-media",
            {
              attrs: { "vertical-align": "top" },
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-3.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Cookie candy ")
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-media",
            {
              attrs: { "vertical-align": "top" },
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Tootsie roll dessert ")
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " Tootsie roll dessert tart candy canes ice cream gingerbread cookie. Jelly jelly-o bear claw bear claw halvah. Biscuit icing pastry tootsie roll gingerbread croissant chupa chups. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-media",
            {
              attrs: { "vertical-align": "top" },
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-1.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Tootsie roll dessert ")
              ]),
              _c("b-card-text", { staticClass: "mb-0" }, [
                _vm._v(
                  " Tootsie roll dessert tart candy canes ice cream gingerbread cookie. Jelly jelly-o bear claw bear claw halvah. Biscuit icing pastry tootsie roll gingerbread croissant chupa chups. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }