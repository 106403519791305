var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Left Aligned Bordered Media" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLeftAlignBorder) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Wrap media list using ")]),
        _c("code", [_vm._v(".media-bordered")]),
        _c("span", [_vm._v(" class for bordered Media Object.")])
      ]),
      _c(
        "div",
        { staticClass: "media-list media-bordered" },
        [
          _c(
            "b-media",
            {
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-23.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Cookie candy ")
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Cookie candy dragée marzipan gingerbread pie pudding. Brownie fruitcake wafer bonbon gummi bears apple pie. Brownie lemon drops chocolate cake donut croissant cotton candy. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-media",
            {
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-16.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Tootsie roll dessert ")
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Tootsie roll dessert tart candy canes ice cream gingerbread cookie. Jelly jelly-o bear claw bear claw halvah. Biscuit icing pastry tootsie roll gingerbread croissant chupa chups. "
                )
              ])
            ],
            1
          ),
          _c(
            "b-media",
            {
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function() {
                    return [
                      _c("b-img", {
                        attrs: {
                          src: require("@/assets/images/portrait/small/avatar-s-15.jpg"),
                          "blank-color": "#ccc",
                          width: "64",
                          alt: "placeholder"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("h4", { staticClass: "media-heading" }, [
                _vm._v(" Tootsie roll dessert ")
              ]),
              _c("b-card-text", [
                _vm._v(
                  " Tootsie roll dessert tart candy canes ice cream gingerbread cookie. Jelly jelly-o bear claw bear claw halvah. Biscuit icing pastry tootsie roll gingerbread croissant chupa chups. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }